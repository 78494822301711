
@font-face {
  font-family: Radiance;
  src: url("./fonts/Radiance-Light.otf") format("opentype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}